import { useState } from 'react'

import ContinueToPaymentButton from '~/modules/signup/components/ContinueToPaymentButton'
import RadioInput from '~/components/forms/RadioInput'
import TextInput from '~/components/forms/TextInput'
import useRootData from '~/hooks/useRootData'
import type { Plan } from '~/types/subscriptions'
import { SubscriptionType, SubscriptionFrequency } from '~/types/subscriptions'
import { CollectionMethod } from '~/modules/signup/types/collection-method'
import { SignupStatus } from '~/modules/signup/types/signup-status'
import {
  pricing,
  LATEST_INDIVIDUAL_ANNUAL_PLAN,
  LATEST_INDIVIDUAL_MONTHLY_PLAN,
  LATEST_SCHOOL_ANNUAL_PLAN
} from '~/config/pricing'

type RenewPlanPanelProps = {
  showHeading?: boolean
  onComplete?: () => void
}
export const RenewSubscriptionPanel = ({ showHeading = true, onComplete }: RenewPlanPanelProps) => {
  const { currentCustomer, currentCustomerAccessPermission } = useRootData() || {}
  const { plan, subscriptionUserSeats, collectionMethod, signupStatus } = currentCustomer ?? {}
  const { isCustomerAdmin } = currentCustomerAccessPermission ?? {}

  const subscriptionType = plan?.startsWith('school') ? SubscriptionType.SCHOOL : SubscriptionType.INDIVIDUAL
  const subscriptionFrequency = plan?.includes('monthly') ? SubscriptionFrequency.MONTHLY : SubscriptionFrequency.ANNUAL

  const defaultNewPlan =
    subscriptionType === SubscriptionType.SCHOOL
      ? LATEST_SCHOOL_ANNUAL_PLAN
      : subscriptionFrequency === SubscriptionFrequency.MONTHLY
        ? LATEST_INDIVIDUAL_MONTHLY_PLAN
        : LATEST_INDIVIDUAL_ANNUAL_PLAN

  // Track states for the form
  const [selectedPlan, setSelectedPlan] = useState<Plan>(defaultNewPlan as Plan)
  const [selectedSeats, setSelectedSeats] = useState<number>(subscriptionUserSeats || 1)

  if (!currentCustomer || !isCustomerAdmin) {
    return null
  }

  const willPayByInvoice = collectionMethod === CollectionMethod.SEND_INVOICE

  if (signupStatus && signupStatus !== SignupStatus.PAYMENT_AND_BILLING_DETAILS_ENTERED) {
    return null
  }

  return (
    <div className="flex flex-col space-y-8">
      {showHeading && <h2>Renew your Cub Club subscription</h2>}

      <div className="space-y-4">
        {subscriptionType === SubscriptionType.SCHOOL ? (
          <div>
            <div>
              <label>Number of User Seats:</label>
              <TextInput
                name="subscriptionUserSeats"
                type="integer"
                className="max-w-[100px]"
                defaultValue={subscriptionUserSeats}
                onChange={e => setSelectedSeats(Number(e.target.value))}
              />
            </div>
            <div className="mt-4 space-y-1">
              <div>
                Price per user: <strong>A${pricing[LATEST_SCHOOL_ANNUAL_PLAN]}</strong> per year
              </div>
              <div>
                Total price: <strong>A${pricing[LATEST_SCHOOL_ANNUAL_PLAN] * selectedSeats}</strong> per year
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            <RadioInput
              value={LATEST_INDIVIDUAL_MONTHLY_PLAN}
              label={
                <>
                  Monthly - <strong>A${pricing[LATEST_INDIVIDUAL_MONTHLY_PLAN]}</strong> per month
                </>
              }
              name="plan"
              checked={selectedPlan === LATEST_INDIVIDUAL_MONTHLY_PLAN}
              onChange={e => setSelectedPlan(e.target.value as Plan)}
            />
            <RadioInput
              value={LATEST_INDIVIDUAL_ANNUAL_PLAN}
              label={
                <>
                  Annual - <strong>A${pricing[LATEST_INDIVIDUAL_ANNUAL_PLAN]} per year</strong> (Save 17%!)
                </>
              }
              name="plan"
              checked={selectedPlan === LATEST_INDIVIDUAL_ANNUAL_PLAN}
              onChange={e => setSelectedPlan(e.target.value as Plan)}
            />
          </div>
        )}
      </div>

      {willPayByInvoice && (
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Since you are set up for <strong>invoice payments</strong>, your subscription will be created immediately and
          you will receive a separate invoice for payment to your billing email.
        </p>
      )}

      <ContinueToPaymentButton
        plan={subscriptionType === 'school' ? LATEST_SCHOOL_ANNUAL_PLAN : selectedPlan}
        subscriptionUserSeats={subscriptionType === 'school' ? selectedSeats : undefined}
        text={willPayByInvoice ? 'Start new subscription' : undefined}
        onComplete={onComplete}
      />
    </div>
  )
}

export default RenewSubscriptionPanel
